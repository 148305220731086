

































































































































import mixins from 'vue-typed-mixins'

import dateMixin from "@/mixins/dateMixin"
import suggestionsMixin from "./suggestionsMixin"
import toastMixin from '@/mixins/toasts'

import Routes from "@/constants/routes"

import {
  ServiceModelServicesDto,
  ProductOffersDto,
  VariantDataDto
} from "@happytal/happydom-api"
import { SuggestionDetailed } from '@/types/suggestions'

import { HRow, HCol, HBtn, HIcon, HSideBar, HPopover, HPagination } from "@happytal/bo-ui-library"
import SuggestionCard from "./components/SuggestionCard.vue"
import BasketButton from "@/components/BasketButton.vue"

export default mixins(dateMixin, suggestionsMixin, toastMixin).extend({
  props: [
    'resident_id'
  ],
  components: {
    HRow,
    HCol,
    HBtn,
    HIcon,
    HSideBar,
    HPopover,
    HPagination,
    SuggestionCard,
    BasketButton
  },
  data() {
    return {
      sidebar: false,
      sidebarSuggestion: null as null | SuggestionDetailed,
      pageActive: 1,
      itemsPerPage: 10,
    }
  },
  computed: {
    suggestions(): SuggestionDetailed[] {
      return this.$store.getters["dataSuggestions/suggestionsClosedOrdered"]
    },
    suggestionsFilteredPerPage(): SuggestionDetailed[] {
      return this.suggestions.filter( (_suggestion, index) => {
        return index >= (this.pageActive -1) * this.itemsPerPage && index < this.pageActive * this.itemsPerPage
      })
    },
    serviceModelServices(): ServiceModelServicesDto[] {
      return this.$store.getters["dataSuggestions/serviceModelServices"]
    },
    productOffers(): ProductOffersDto[] {
      return this.$store.getters["dataSuggestions/productOffers"]
    },
    variantData(): VariantDataDto[] {
      return this.$store.getters["dataSuggestions/variantData"]
    },
    paginationLength(): number {
      if (this.suggestions.length > this.itemsPerPage) {
        const paginationLength = Math.floor(this.suggestions.length / this.itemsPerPage)
        return this.suggestions.length % this.itemsPerPage ? paginationLength + 1 : paginationLength
      }
      return 1
    },
    seeCommentText(): string {
      if (this.$aclChecker.some(this.$acl, [
          'isTutor',
          'isFamily',
      ])) {
          return 'Lire mon commentaire'
      }
      return 'Lire le commentaire du référent'
    },
    canViewBasketBtn(): boolean {
      return this.$aclChecker.some(this.$acl, [
        'isTutor',
        'isFamily',
        'isHappytalSupport',
        'isHappytalEmployee'
      ])
    },
    currentUserId(): string {
      return this.$store.getters["dataUser/getUserId"]()
    },
    canEmitSuggestions(): boolean {
      return this.$aclChecker.some(this.$acl, [
          'canEmitSuggestions',
      ])
    },
  },
  methods: {
    openSidebar(suggestion: SuggestionDetailed): void {
      this.sidebar = true
      this.sidebarSuggestion = suggestion
    },
    closeSidebar(): void {
      this.sidebarSuggestion = null
      this.sidebar = false
    },
    onBasketBtnClick(): void {
      this.$router.push({
        name: Routes.ResidentStoreBasket,
        params: {
          resident_id: this.resident_id,
        },
      })
    },
    async onAddProductToBasket(suggestion: SuggestionDetailed): Promise<void> {
      if (suggestion.suggestionProduct && suggestion.suggestionProduct.productSku && suggestion.offer && suggestion.product) {
        const draftProduct = {
          id: suggestion.suggestionProduct.productSku || "",
          type: 'product',
          photo: suggestion.suggestionProduct.imageUrl || null,
          title: suggestion.suggestionProduct.title || "",
          description: suggestion.suggestionProduct.description || "",
          product_quantity: suggestion.suggestionProduct?.quantity,
          legend: '',
          price: suggestion.suggestionProduct.unitPrice || 0,
          frequency: suggestion.suggestionProduct.frequencyInMonth,
          quantity: suggestion.suggestionProduct.quantity || 0,
          meta: {
            product: suggestion.product,
            offer: suggestion.offer,
            variantInfo: {
              data: this.variantData
            }
          }
        }
        await this.$store.dispatch('dataDrafts/add', {
          userId: this.currentUserId,
          residentId: this.resident_id,
          type: this.$_getOrderTypeFromBuyMode(suggestion),
          draftProduct
        })
        this.$_showInfoToast('Le produit a bien été ajouté au panier')
      }
      else if (suggestion.suggestionService && suggestion.serviceModel && suggestion.service) {
        const draftProduct = {
          id: suggestion.suggestionService.code,
          type: 'service',
          photo: (suggestion.serviceModel ?.publicData as any)?.imageUrl,
          title: suggestion.suggestionService.title,
          description: suggestion.suggestionService.description,
          legend: '',
          price: this.$_getSuggestionPrice(suggestion),
          frequency: suggestion.suggestionService.frequencyInMonth,
          quantity: suggestion.suggestionService.quantity,
          meta: {
            service: suggestion.service,
            serviceModel: suggestion.serviceModel ,
            options: suggestion.suggestionService.options
          }
        }
        await this.$store.dispatch('dataDrafts/add', {
          userId: this.currentUserId,
          residentId: this.resident_id,
          type: this.$_getOrderTypeFromBuyMode(suggestion),
          draftProduct
        })
        this.$_showInfoToast('Le service a bien été ajouté au panier')
      }
    },
    scrollToTop(): void {
      document.querySelector('#page-suggestions')?.scrollIntoView({
        behavior: 'smooth'
      }) //TODO: Change this
    }
  },
})
