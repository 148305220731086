import Vue from 'vue'

export default Vue.extend({
  methods: {
    $_showInfoToast (text: string) {
      this.$toasted.show(text, {
        theme: 'hx-toasted',
        position: 'hx-bottom-left',
        duration: 4000,
        action: [
          {
            text: 'Fermer',
            onClick: (e: any, to: any) => {
              to.goAway(0)
            }
          }
        ]
      })
    },
    $_showErrorToast (text: string) {
      this.$toasted.show(text, {
        theme: 'hx-toasted',
        position: 'hx-bottom-left',
        duration: 4000,
        action: [
          {
            text: 'Fermer',
            onClick: (e: any, to: any) => {
              to.goAway(0)
            }
          }
        ]
      })
    }
  },
})
